/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-multiple-dates/prebuilt-themes/indigo-pink.css";
// @import "~ngx-ui-switch/ui-switch.component.scss";
.bs-timepicker-field {
  width: 39px !important;
}

.swal2-popup.swal2-modal.swal2-show {
  font-size: 14px;
  width: 30rem;
  padding-bottom: 2.2rem;
}

#swal2-content {
  margin-bottom: 10px;
}

.card .body,
.table {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
  // border-radius: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #3b3c3c;
  border-radius: 5px;
}

.animationAlert {
  animation: blit-fontweight-color alternate-reverse ease-in-out 1000ms infinite;
  // color: #fff;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 30px;
  font-weight: bold;
}

textarea {
  resize: none !important;
}

// input,select {
//   &:focus {
//     border: 1px solid #737474 !important;
//     box-shadow : none;
//     border-color: #737474;
//   }
// }

.sidebar .menu .list .ml-menu .activeSub .ml-menu-3 {
  display: block;
}

.sidebar .menu .list .ml-menu-3 {
  list-style: none;
  display: none;
  padding-left: 0;
}

@keyframes blit-fontweight-color {
  from {
    background-color: var(--ion-color-danger);
    opacity: 0.3;
  }
  to {
    background-color: var(--ion-color-danger);
    opacity: 1;
  }
}

::ng-deep .mat-expansion-panel {
  background-color: #ededed !important;
}

::ng-deep .mat-expansion-panel-body {
  background-color: #fff;
  padding: 10px !important;
}
::ng-deep .cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

.badge-orange {
  background-color:rgb(236, 203, 142) ;
  color: rgb(67, 66, 66);
}

.badge-danger {
  background-color: rgb(203, 86, 86);
  color: #fff;
}

.badge-success {
  background-color: rgb(143, 180, 91);
  color: #fff;
}

.badge-validate {
  background-color: rgb(73, 102, 200);
  color: #fff;
}

.img-assure{
  width: 100px;
  height: 104px;
  border: 1px solid #eceeee;
  padding: 5px;
  margin-top: 20px;
}
